import React from 'react'
import Logo from '../images/logo.inline.svg'

function ChatUnavailable() {
  return (
    <div className="text-center">
      <div className="mb-10 p-4" style={{ backgroundColor: '#04b2e1' }}>
        <Logo className="inline-block bg-white" />
      </div>
      <p className="mb-5 text-gray-700">
        Live Chat is currently unavailable but should be back online soon.
      </p>
      <p className="mb-5 text-gray-700">
        For immediate service, call us at{' '}
        <a
          className="underline"
          href="tel:18005804985"
          style={{ color: '#04b2e1' }}
        >
          800.580.4985
        </a>
        .
      </p>
      <button
        className="text-white py-3 px-6 text-lg"
        type="button"
        onClick={typeof window === 'undefined' ? null : window.close()}
        style={{ backgroundColor: '#04b2e1' }}
      >
        Close Chat
      </button>
    </div>
  )
}

export default ChatUnavailable
